import React, { FunctionComponent } from "react"

import "./styles.scss"
import Animate from "~/components/olc-framework/Animate"

const Section: FunctionComponent<any> = ({ className = "", children, ...props }) => {
  return (
    <Animate
      properties={["opacity", "transform"]}
      startValues={["0", "translateY(40px) rotate(0.5deg)"]}
      endValues={["1", "translateY(0) rotate(0deg)"]}
      {...props}
    >
      <div style={{backgroundColor:"blue"}} className={`section ${className}`} {...props}>
        
        <div className="container container--column" {...props}>{children}</div>
      </div>
    </Animate>
  )
}

export default Section
