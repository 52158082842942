import React, { useContext } from "react"
import Hero from "~/components/configurable/Hero"
import Block from "~/components/configurable/Block"
import Heading from "~/components/configurable/Heading"
import BlockCTA from "~/components/configurable/BlockCTA"
import Col6 from "~/components/grid/Col6"
import { window } from 'global';
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Section from "../configurable/Section"
import Col12 from "../grid/Col12"
import "./solar-terminology.scss"
import TickList from "../configurable/TickList"

const SolarTerminology = ({ location }) => {

  return (
    <div className="blank">
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          Solar Terminology
        </Heading>
      </Hero>
      <Block className="segBlock">
        <Section>
          <div className="container container--column terminology">
            <Col12>
              <Heading level={1}>Solar Acronyms & Terms</Heading>
              <TickList>

                <li>
                  PV <span> <br/> Photovoltaic, simply the transfer of light (photo) into electric (voltaic).
                  </span>
                </li>
                <li>
                  W <span> <br/> Watts, a measurement of power.
                  </span>
                </li>
                <li>
                  kW <span> <br/> 1000 watts
                  </span>
                </li>
                <li>
                  AC-Coupled <span> <br/>AC coupled battery storage systems, are an ideal solution for retrofitting to an existing Solar PV system; already in place. If you have panels in place already, this is a solution for you.
                  </span>
                </li>
                <li>
                  DC-Coupled <span> <br/> DC (direct current) batteries tend to be installed at the same time as solar panels as they connect directly to the panels with an Inverter.
                  </span>
                </li>
                <li>
                  Panel/Module <span> <br/>  Is the name given to each individual solar panel, typically measuring around 1 meter wide and up to 2 meters long, it is a collection of silicon cells that generate electric current when photons (light particles) hit the silicon.
                  </span>
                </li>
                <li>
                  Array <span> <br/> An array is a group of panels/modules working together to feed into an inverter.
                  </span>
                </li>
                <li>
                  Wp <span> <br/> Watts peak.  This refers to the peak power of a panel and is measured under STC (Standard Test Conditions) which are similar to a bright sunny day, hence the term ‘peak’ as this is likely to be the highest output you’ll see from the panel.   Typical panels for the UK market are between 300Wp and 500Wp.
                  </span>
                </li>
                <li>
                  kWp <span> <br/> kilo Watts peak. 1kWp = 1000Wp. Domestic arrays are usually between  4kWp and 20kWp, Commercial arrays average between 20kWp and  500kWp and industrial rooftop arrays can be 1000kWp (or 1MWp) or more.
                  </span>
                </li>
                <li>
                  kWh <span> <br/> kilo Watt-hour. This is the same as one unit of electric. We buy electric in units or kWh. If you use 1 kW (kiloWatt) for one hour then you will have used a single unit of electric i.e. a kWh.
                  </span>
                </li>
                <li>
                  Inverter <span> <br/> The inverter is a ‘box’ that sits in the building changing the DC electric from the array to AC electric that is acceptable for your appliances and the national grid.
                  </span>
                </li>
                <li>
                  Mounting system <span> <br/> This is the frame, brackets, hooks etc required to hold the panels in place. Various solutions are available to deal with the many situations that which Solar PV panels can be mounted in. For more information on our different mountings, please go to our Products Page and Select Roof Mountings
                  </span>
                </li>
                <li>
                  FIT <span> <br/> The FIT scheme is a government programme designed to promote the uptake of small-scale renewable and low-carbon electricity generation technologies. Introduced on 1 April 2010, the scheme requires participating licensed electricity suppliers (FIT Licensees) to make payments on both generation and export from eligible installations.
                  </span>
                </li>
                <li>
                  Export <span> <br/> Is the electricity that is generated but not used in the property.  Exporting will happen when the property is using less electricity than is being generated by the solar array.  Therefore this excess electricity spills over and is exported to the grid.  Various energy suppliers will pay you for your export.
                  </span>
                </li>
              </TickList>
            </Col12>
          </div>
        </Section>
      </Block>
    </div>
  )
}

export default SolarTerminology
